<template>
  <div>
    <div class="row pt-5">
      <payments-block text="Pending" :value="stats.pending" type="outgoing" />
      <payments-block
        text="Requested"
        :value="stats.requested"
        type="outgoing"
      />
      <payments-block text="Verified" :value="stats.verified" type="outgoing" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentsBlock from "@/view/layout/dashboard_components/PaymentsBlock";
export default {
  name: "VendorPaymentsStats",
  components: {
    PaymentsBlock,
  },
  computed: {
    ...mapState({
      stats: ({ payments }) => payments.vendorPaymentsStats,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
